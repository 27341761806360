import { useState } from "react";
import blinkscribelogo from '../../../assets/blinkscribe 4.svg';
import api from "../../../api/api.js";
const TermsOverlayCard = ({ isOpen, handleVerified}) => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  if (!isOpen) return null;

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handlesubmit=async()=>{
    try {
      setLoading(true)
      const response = api.put('/v1/users/disclaimer',{
        
          "acceptance_status": isChecked
        
      })
      handleVerified()
    } catch (error) {
      console.log(error)
    }finally{

      setLoading(false)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 sm:p-6 rounded-[30px] shadow-lg w-[90%] sm:w-[80%] max-w-2xl">
        <div className="flex flex-col justify-center h-full">
          <div className="flex justify-center mb-4">
            <img src={blinkscribelogo} alt="BlinkScribe Logo" className="h-16 w-auto sm:h-20" />
          </div>

          <div className="mb-4 text-center w-[70%] mx-auto">
            <div className="font-semibold text-[#1c2a3a] text-lg sm:text-xl leading-7">Terms & Conditions</div>
            <div className="font-normal text-gray-500 text-xs sm:text-xs leading-[22.5px]">
              By using this app you are agreeing to our terms and conditions.
            </div>
          </div>

          {/* Disclaimer Card */}
          <div 
            style={{
              borderRadius: "20px",
              background: "#FFF",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
            }}
            className="w-[90%] p-6 mx-auto mb-4 rounded-[20px] shadow-[0px 4px 4px rgba(0, 0, 0, 0.25) inset h-48 overflow-hidden"
          >
            <div className="font-semibold text-start text-gray-500 text-lg mb-2">Disclaimer:</div>
            <div className="font-normal text-start text-gray-500 text-xs leading-[22.5px] h-full overflow-auto custom-scrollbar p-4">
              Welcome to BlinkScribe, an innovative software solution leveraging artificial intelligence to automatically transcribe and create medical notes for healthcare professionals. Before utilizing our system, please be aware of the following important information:
              <br /><br />
              1. Training Required: The AI technology underpinning BlinkScribe requires an initial training phase and ongoing learning to adapt to your specific documentation needs. The quality of transcriptions and the system's understanding of medical terminology will enhance as it processes more data.
              <br /><br />
              2. Professional Review Required: While BlinkScribe aims to streamline note-taking and reduce administrative burdens, it is crucial that all healthcare professionals review and verify the accuracy of the AI-generated notes. The responsibility for the final content and clinical decisions remains with the healthcare provider.
              <br /><br />
              3. Accuracy Improves Over Time: The performance of BlinkScribe's AI technology is designed to improve over time. As the system encounters a broader range of vocabulary, accents, and speech patterns, its ability to accurately transcribe notes will increase. We encourage regular use and feedback to help expedite this process.
              <br /><br />
              By using BlinkScribe, you acknowledge the need for initial and ongoing training of the AI, the necessity of professional oversight, and the understanding that accuracy will improve with consistent use. We are committed to supporting healthcare professionals in delivering high-quality care through advanced technology, and we welcome your partnership in achieving this goal.
            </div>
          </div>

          {/* Checkbox Section */}
          <div className="flex items-center mb-4 w-[80%] mx-auto sm:w-[60%]">
            <input
              type="checkbox"
              id="termsCheckbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="termsCheckbox" className="text-xs text-gray-700">
               I understand & agree to the Terms and Conditions
            </label>
          </div>

          {/* Continue Button */}
          <div className="w-[80%] mx-auto mb-4 sm:w-[60%]">
            <button
              className={`w-full h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-full ${loading || !isChecked ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
              disabled={loading || !isChecked}
              onClick={()=>handlesubmit()}
            >
              {loading ? 'Updating' : 'Continue'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOverlayCard;
