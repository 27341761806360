import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { Preview } from '@mui/icons-material';
import DocPreview from './preview';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import api from 'api/api';
import ToastMessage from 'utils/muialerts/righttoptoast';
import arrowleft from '../../assets/arrowleft.png';
const EditTemplate = () => {
    const [editingId, setEditingId] = useState(null);
    const { name,flow} = useParams();
    const location = useLocation();
    const { extractionresponse } = location.state || {};
    const [pId,setPId]=useState('')
    const [alertmessage, setAlertMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const[dId,SetDId]=useState('')
    const [loading,setLoading]=useState(false)
    const[data,setData]=useState([])
    const navigate=useNavigate()
    const [templatename,setTemplateName]=useState('')
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    const fetchdata=async()=>{
        try {
          setLoading(true)
          const response = await api.get('/auth/me')
          if (response && response.config) {
            const practiceIdString = String(response.config.practiceid); 
            const departmentIdString = String(response.config.departmentid);
          setPId(practiceIdString)
          SetDId(departmentIdString)
            const responsedata = await api.post('/v1/user_templates/get_all_templates', {
              practiceid: practiceIdString,
              departmentid: departmentIdString,
            });
if (responsedata.status === 200) {
    const filteredData = responsedata.file_names.reduce((acc, obj) => {
      const entry = Object.entries(obj).find(([key, value]) => key === name);
      if (entry) {
        acc[entry[0]] = entry[1]; // Add the key-value pair to the accumulator
      }
      return acc;
    }, {});
    
      setData(filteredData);
    
    const templateData = filteredData[Object.keys(filteredData)[0]];
    const cleanedName = Object.keys(filteredData)[0].replace(/[^a-zA-Z]/g, '');
    setTemplateName(cleanedName)
 
    // // // Create new entries from filteredData
    const newEntries = Object.keys(templateData).map((key, index) => ({
        id: historyTexts.length + index + 1, // Generate a new unique id
        title: key,
        text: templateData[key].replace(/{{/g, '').replace(/}}/g, '').trim() // Remove {{ and }} symbols
    }));
    // // Add the new entries to the existing historyTexts
    console.log("newentrie",newEntries)
    setHistoryTexts([...newEntries]);
  }  
            else{
             setData([])
            }
         
          }
          
        } catch (error) {
            setData([])
          console.log(error)
    
        }finally{
          setLoading(false)
        }
    
      }
      const fetchuser=async()=>{
        const response = await api.get('/auth/me')
        if (response && response.config) {
          const practiceIdString = String(response.config.practiceid); 
          const departmentIdString = String(response.config.departmentid);
        setPId(practiceIdString)
        SetDId(departmentIdString)
      }
    }
useEffect(()=>{
    if(flow==='ind'){
        fetchdata()
    }else if(flow==='self'){
        setData([{}])
        const cleanedName = name.replace(/[^a-zA-Z]/g, '');
    setTemplateName(cleanedName)
            fetchuser()
        const newEntries = Object.keys(extractionresponse).map((key, index) => ({
            id: historyTexts.length + index + 1, // Generate a new unique id
            title: key,
            text: extractionresponse[key].replace(/{{/g, '').replace(/}}/g, '').trim() // Remove {{ and }} symbols
        }));
        // // Add the new entries to the existing historyTexts
     
        setHistoryTexts([...newEntries]);
    }
},[])

    const [historyTexts, setHistoryTexts] = useState([]);

    const handleTitleChange = (id, e) => {
        const updatedTexts = historyTexts.map((item) =>
            item.id === id ? { ...item, title: e.target.value } : item
        );
        setHistoryTexts(updatedTexts);
    };
    const handleTextChange = (id, e) => {
        const updatedTexts = historyTexts.map((item) =>
            item.id === id ? { ...item, text: e.target.value } : item
        );
        setHistoryTexts(updatedTexts);
    };

    const toggleEdit = (id) => {
        setEditingId((prev) => (prev === id ? null : id));
    };

    const handleSave = () => {
        setEditingId(null);
    };

    const handleDelete = (id) => {
        const updatedTexts = historyTexts.filter(item => item.id !== id);
        setHistoryTexts(updatedTexts);
    };
const handleFinalSave=async()=>{
setLoading(true)
try {
    const result = historyTexts.reduce((acc, obj) => {
        acc[obj.title] = `{{${obj.text}}}`;

        return acc;
      }, {});
      console.log(result)
    const  savetemplate=await api.post('/v1/user_templates/save_template',{
        "practiceid": pId,
        "departmentid":dId,
        "filename": templatename,
        "note_json": result
      
      })
      if(savetemplate.status===200){
        setOpen(true);
        setAlertMessage('Successfully saved the template');
      }else{
        setOpen(true);
        setAlertMessage('Something Went Wrong')
      }

} catch (error) {
    
}finally {
    

    // Wait for 2 seconds before navigating
    setTimeout(() => {
        navigate('/temp');
    }, 2000);
}

    
}
    return (<>
     <div className="flex items-center justify-center w-full h-full">
            {loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" size={40} />
                </Backdrop>
            ) : data.length === 0 ? (
                <div className="text-center text-red-500 font-medium text-lg">
                    Something went wrong, please try again.
                </div>
            ) : (
                <div className="w-[95%] md:w-[90%] min-h-[500px] h-auto md:mt-10 md:mb-10 p-12 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040] flex flex-col sm:flex-row">
                    {/* Your regular component content goes here if data is available */}
                    <div onClick={()=>navigate(-1)}
              className="flex items-start cursor-pointer relative bottom-6 right-4 ">
              <img src={arrowleft} alt="Down Arrow" className="w-6 h-6" />
            </div>
                    <div className="flex-1 pr-4">
                        
                        <div className="flex flex-col">
                        <div className="flex flex-col">
                            
                        <div className="text-base font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                            Template Name
                            <span className="label required text-red-400"> *</span>
                        </div>
                        <div className="flex items-start gap-3 mt-2">
                           <div className='w-full '> 
                            <input
                                id="templateName"
                                type="text"
                                className="border border-gray-300 w-full rounded-md p-2"
                                placeholder="Template name"
                                value={templatename}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^[a-zA-Z0-9]*$/.test(value)) {
                                        setTemplateName(value);
                                    }
                                }}
                                
                            />
                                 <div class="text-sm text-red-500 mt-2 text-start">
  No special characters or spaces are allowed.
</div>

                            </div>
                            <button 
                            onClick={()=>handleFinalSave()}
                            className="w-[40%] sm:w-[18%] md:w-[18%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg">
                                Save
                            </button>
                       
                        </div>

                        {historyTexts.map(({ id, title, text }) => (
                            <div key={id} className="flex flex-col mt-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        {editingId === id ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={title}
                                                    onChange={(e) => handleTitleChange(id, e)}
                                                    className="border border-gray-300 rounded-md p-2 w-full"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faSave}
                                                    className="ml-2 text-green-500 cursor-pointer"
                                                    onClick={handleSave}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <div className="text-base font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                                                    {title}
                                                    <span className="label required text-red-400"> *</span>
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className="ml-2 text-gray-500 cursor-pointer"
                                                    onClick={() => toggleEdit(id)}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-red-500 cursor-pointer"
                                        onClick={() => handleDelete(id)}
                                    />
                                </div>
                                <textarea
                                    value={text}
                                    onChange={(e) => handleTextChange(id, e)}
                                   // readOnly // Keep the text area read-only for displaying details
                                    className="border border-gray-300 rounded-md p-2 mt-2 h-18 shadow-[0px_1px_3px_#00000040] "
                                    placeholder="Enter details here..."
                                />
                            </div>
                        ))}
                    </div>
                        </div>
                    </div>

                    <div className="w-full sm:w-[605px] mb-2 ml-4">
                        <h3 className="text-lg font-semibold mb-2 opacity-55">Preview</h3>
                        <div className="border border-gray-300 rounded-md shadow-[0px_2px_13px_#00000040] p-4 h-full min-h-[80vh]">
                            <DocPreview data={historyTexts}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    
        <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
    
    </>

    );
};

export default EditTemplate;
