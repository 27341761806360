import editrecord from '../../assets/editrecord.png';
import document from '../../assets/document.png';
import { useEffect, useState } from 'react';
import UploadFileModal from './uploadfilemodal';
import { useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import api from 'api/api';
import ToastMessage from 'utils/muialerts/righttoptoast';
import ShowPdf from './showpdf';
import arrowleft from '../../assets/arrowleft.png';
import { FaCheckCircle } from 'react-icons/fa';
const ManageTemplate = () => {
  // Array of cards with template names
  const [isuploadmodal,setIsuploadmodal]=useState(false)
  const [ispdfshow,setIspdfShow]=useState(false)
  const[file,setFile]=useState('')
  const [pdffile,setPdffile]=useState()
  const [loading,setLoading]=useState(false)
  const [alertmessage, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [default_template,setDefaulttemplate]=useState('')
  const [severity, setSeverity] = useState('success');
  const[extractionResponse,setExtractionResponse]=useState('')
  const [pId,setPId]=useState('')
  const[dId,SetDId]=useState('')
  
  const navigate=useNavigate()
  const[cards,setCards]=useState([])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const fetchdata=async()=>{
    try {
      setLoading(true)
      const response = await api.get('/auth/me')
      if (response && response.templates && response.templates.default_template) {
                  setDefaulttemplate(response.templates.default_template);
      }
      if (response && response.config) {
        const practiceIdString = String(response.config.practiceid); 
        const departmentIdString = String(response.config.departmentid);
      setPId(practiceIdString)
      SetDId(departmentIdString)
        const data = await api.post('/v1/user_templates/get_all_templates', {
          practiceid: practiceIdString,
          departmentid: departmentIdString,
        });
        if(data.status===200){
setCards(data.file_names)
        }else{
          setCards([])
        }
     
      }
      
    } catch (error) {
      console.log(error)

    }finally{
      setLoading(false)
    }

  }
useEffect(()=>{
fetchdata()
},[])
const handleUploadSavedTemplate=async()=>{
  setLoading(true)
  try {
    const  savetemplate=await api.post('/v1/user_templates/save_template',{
      "practiceid": pId,
      "departmentid":dId,
      "filename": file.name,
      "note_json": extractionResponse
    
    })
if(savetemplate.status===200){
  setAlertMessage('Successfully saved the template') 
  setOpen(true)
  setFile('')
}else{
  setAlertMessage('Something Went Wrong, Try Again') 
  setOpen(true)
}
  } catch (error) {
    setAlertMessage('Something Went Wrong, Try Again') 
  setOpen(true)
    console.log(error)
  }finally{
setLoading(false)
fetchdata()
  }
}
const markasdefault= async(prop)=>{
  try {
    let response= await api.put('/v1/users/update-templates',{
"default_template":prop
    })
   
    if(response.status===200){
   
      setAlertMessage(response.message) 
      setOpen(true)
      fetchdata()
    }else{
      setAlertMessage(response.message) 
      setOpen(true)
    }
  } catch (error) {
    console.log(error)
  }


}
const handleSelfEdit = () => {
  navigate(`/edit-temp/${file.name}/self`, { state: { extractionresponse:extractionResponse } });
};

const handleShowpdf= async(filename)=>{
try {
  setLoading(true)
 const response = await api.post('/v1/user_templates/sample_note',{
    "departmentid":dId,
    "practiceid": pId,
    "filename": filename  
 },{
  responseType: "blob", 
 })

 const pdfBlob = new Blob([response], { type: 'application/pdf' });

 setPdffile(pdfBlob)
 setIspdfShow(true)

 console.log(response)
} catch (error) {
  console.log(error)
}finally{
setLoading(false)

}

}

  return (
    <div className="flex items-center justify-center w-full h-screen sm:h-auto md:h-auto">
      <div className="w-[95%] md:w-[628px] min-h-[50px] h-auto md:mt-10 p-8 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040]">
      <div onClick={()=>navigate(-1)}
              className="flex items-start cursor-pointer">
              <img src={arrowleft} alt="Down Arrow" className="w-4 h-4" />
            </div>
      <div className="mt-4 w-full flex justify-start">
      
      <div className="text-base ml-[10%] font-medium  [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
          Add New Template
          <span className="label required text-red-400"> *</span>
        </div>
        </div>     

        <div className="mt-4 w-full flex justify-center">
          {/* Custom File Field */}
          <div className="flex w-[80%] border border-[#27509E] rounded-md overflow-hidden" 
          onClick={()=>{setIsuploadmodal(true)}}
          >
            <input
              type="text"
              placeholder="Choose file"
              className="w-full px-2 py-1 text-md focus:outline-none"
              readOnly
            />
            <button  className="bg-[#E6E6E6] px-3 py-1 text-[#595959] text-sm rounded-sm ml-1 mr-2 my-1">
              Browse
            </button>
          </div>
        </div>
        {file && (
          <div className="ml-[10%] font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
            File Name: {file.name}
          </div>
        )}
        {/* Action Buttons */}
        <div className="mt-6 flex justify-center space-x-4">
        <button
        onClick={handleUploadSavedTemplate}
        disabled={!file}
        className={`w-[40%] sm:w-[30%] md:w-[28%] h-10 text-white text-sm font-semibold rounded-lg ${
          file 
            ? 'bg-[#1C2A3A] hover:bg-[#173041]'  // Active styles
            : 'bg-[#1C2A3A] cursor-not-allowed'    // Disabled styles
        }`}
      >
        Upload
      </button>
          <button
          onClick={()=>handleSelfEdit()}
          disabled={!file}
        className={`w-[40%] sm:w-[30%] md:w-[28%] h-10 text-white text-sm font-semibold rounded-lg ${
          file 
            ? 'bg-[#1C2A3A] hover:bg-[#173041]'  // Active styles
            : 'bg-[#1C2A3A] cursor-not-allowed'    // Disabled styles
        }`}
          
          >
            Edit Template
          </button>
        </div>

        {/* Manage Template Section */}
        <div className="mt-10">
          <div className="text-base text-lg font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-center">
            Manage Templates
          </div>
        </div>

        {/* Scrollable Cards Section */}
        <div className="mt-4 h-70 overflow-y-auto custom-scrollbar">
        {(cards.length)?cards.map((file, index) => {
  // Extract the key of the template, which is "1st_template" in your case
  const templateName = Object.keys(file)[0];
  
  return (
    <div
      key={index}
      className="w-[70%] mx-auto border border-[#D1D1D1] rounded-md px-4 py-2 mb-4 flex items-center justify-between"
      

    >
      <div className="flex items-center gap-4">
        <img src={document} 
        onClick={()=>handleShowpdf(templateName)}
        className="w-6 h-6 cursor-pointer" alt="document" />
        <div className="[font-family:'Poppins-Regular',Helvetica] font-normal text-start text-[#262629] text-[13px] tracking-[0] leading-[normal]">
          {templateName} {/* Display the template name dynamically */}
        </div>
      </div>
      {templateName===default_template ? (
        <div className="text-xs opacity-50 ml-2">Default</div>
      ) : (
        <div className="text-xs opacity-50 ml-2"></div>
      )}
   {templateName!==default_template?<button
    onClick={()=>markasdefault(templateName)}
    className="bg-[#007BFF] text-white text-xs py-1 px-2 rounded-md hover:bg-[#0056b3] transition duration-200">
    Mark as Default
  </button>:<></>}
      <img src={editrecord}   onClick={() => navigate(`/edit-temp/${templateName}/ind`)} className="w-6 h-6 cursor-pointer" alt="edit" />
    </div>
  );
}):<>{loading?<>Getting Data</>:"No Data Available"}</>}

        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
      <ShowPdf ispdfshow={ispdfshow} setIspdfShow={setIspdfShow} pdfBlob={pdffile}/>
      <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
      {isuploadmodal?<UploadFileModal 
      setAlertMessage={setAlertMessage}
      patientId={pId}
      deptID={dId}
      setExtractionResponse={setExtractionResponse}
      setOpen={setOpen}
      setfile={setFile} 
      setIsuploadmodal={setIsuploadmodal}/>:<></>}
    </div>
  );
};

export default ManageTemplate;
