import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe.png";
import Select from "react-select";
import Flag from "react-world-flags";
import { useNavigate } from "react-router-dom";

import user from '../../../assets/user.png'
import sms from '../../../assets/sms.png'
import arrowleft from '../../../assets/arrowleft.png'
const countries = [
  { code: "US", label: "United States", value: "US" },
  { code: "IN", label: "India", value: "IN" },
  { code: "GB", label: "United Kingdom", value: "GB" },
  { code: "DE", label: "Germany", value: "DE" },
  { code: "FR", label: "France", value: "FR" },
];

const BlinkScribeSignup = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.value === "US")
  ); 
  const navigate = useNavigate();

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  const formatOptionLabel = ({ code, label }) => (
    <div className="flex items-center">
      <Flag
        code={code}
        className="mr-2"
        style={{ width: "20px", height: "15px" }}
      />
      <span>{label}</span>
    </div>
  );

  return (
    <div className="flex justify-center items-center h-screen">
      <Card className="w-[100%] md:w-[40%] sm:w-[40%] max-w-lg md:max-w-2xl p-6 shadow-lg h-screen md:h-[600px] sm:h-[600px] rounded-lg bg-white  md:bg-white">
        <CardContent className="h-full">
        <div className="flex items-start ">
            <img
              onClick={() => {
                navigate("/auth1");
              
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center justify-between h-full">
            <div className="flex justify-center">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-16 w-auto"
              />
            </div>
            {/* heading */}
            <div>
            <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-xl tracking-[0] leading-9 whitespace-nowrap">
            Create Account
      </div>
      <div className=" [font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[13px] tracking-[0] leading-[22.5px] whitespace-nowrap">
      We are here to help you!
      </div>
            </div>
<div className="w-full flex flex-col items-center gap-4">
<div className='w-[100%] md:w-[60%] sm:w-[60%]'>
            <input
            
                  type="text"
                  placeholder="Enter First Name"
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
                </div>
                <div className='w-[100%] md:w-[60%] sm:w-[60%]'>
            <input
            
                  type="text"
                  placeholder="Enter Last Name"
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
                </div>
                <div className='w-[100%] md:w-[60%] sm:w-[60%]'>
            <input
            
                  type="text"
                  placeholder="Enter Email"
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
                </div>
            <div
              style={{ border: "1px solid #dcdcdc" }}
              className="flex flex-row w-[100%] md:w-[60%] sm:w-[60%] rounded-lg p-1 gap-2"
            >
              <div style={{ width: "80px" }} className="flex items-center">
                <Select
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  options={countries}
                  styles={customStyles}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
              <div className="flex-1">
                <input
                  type="tel"
                  placeholder="Enter Phone Number"
                  className="w-full bg-transparent h-[42px] md:h-[32px] sm:h-[32px] p-3 rounded-lg outline-none text-gray-800 text-sm font-normal"

                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
            </div>
           
     </div>
<div className="w-full flex items-center flex-col gap-4">

<button
              
              className={`flex w-[100%] md:w-[60%] sm:w-[60%] h-15 md:h-10 sm:h-10  items-center justify-center gap-2.5 px-6 py-4 rounded-full 
              bg-black text-white' : 'border border-neutral-500 text-white
                transition duration-300 ease-in-out`}
            >
              <div className="font-semibold text-base">Create Account</div>
            </button>
      <p className="[font-family:'Inter-Regular',Helvetica] font-normal text-transparent text-[15px] text-center tracking-[0] leading-[22.5px]">
        <span className="text-gray-500">Have an account yet?</span>
        <span className="text-gray-500 text-sm leading-[21px]">&nbsp;</span>
        <span
        onClick={()=>navigate('/auth1')}
        style={{cursor:"pointer"}}
        className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#1c64f2] text-sm leading-[21px]">
          Sign in
        </span>
      </p>
</div>
            <div >
              <div
                className="font-semibold text-base text-center cursor-pointer"
                style={{ color: "#0F172A", fontSize: "15px", fontWeight: "500", lineHeight: "150%" }}
              >
                Need Help?
              </div>
            </div>

          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BlinkScribeSignup;
