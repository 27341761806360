import { Backdrop, CircularProgress } from '@mui/material';
import api from 'api/api';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const UploadFileModal = ({ setIsuploadmodal,setExtractionResponse,setAlertMessage,setfile,patientId,deptID,setOpen}) => {
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const[loading,setLoading]=useState(false)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'], // Added support for PDF
      'image/png': ['.png'],         // PNG support
      'image/jpeg': ['.jpeg', '.jpg'] // JPG and JPEG support
    },
    onDrop: (acceptedFiles) => {
      setUploadedSignature(acceptedFiles[0]);
      setfile(acceptedFiles[0])
    }
  });
  const handleSave = async () => {
    try {
      setLoading(true)
      const formData = new FormData();
        formData.append('file', uploadedSignature);
        const uploadedFile = await api.post('/v1/user_templates/template_extraction', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if(uploadedFile.status===200){
        setExtractionResponse(uploadedFile.output)
// const  savetemplate=await api.post('/v1/user_templates/save_template',{
//   "practiceid": patientId,
//   "departmentid": deptID,
//   "filename": "1st_template",
//   "note_json": uploadedFile.output
// })
        setAlertMessage('Template Uploaded Successfully') 
        setOpen(true)       
        setUploadedSignature(null);
        setIsuploadmodal(false);
      }else{
        setAlertMessage('Something Went Wrong') 
        setOpen(true)       
        setUploadedSignature(null);
        setIsuploadmodal(false);

      }
        console.log('File uploaded successfully:', uploadedFile);
    } catch (error) {
      console.error('Error uploading file:', error);
      setAlertMessage('Something Went Wrong') 
      setOpen(true)       
      setUploadedSignature(null);
      setIsuploadmodal(false);
    } finally {
    setLoading(false)   
    }
  };
  

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-[90%] sm:w-[30%] h-[60%] flex flex-col justify-around rounded-md shadow-lg p-6">
        {/* Adjusting text alignment to start from the left */}
        <div className="mb-4 w-full">
          <div className="font-semibold text-[#1c2a3a] text-lg sm:text-xl leading-7 text-left">Upload File</div>
        </div>

        {/* Signature Upload Section */}
        <div {...getRootProps()} className="flex flex-col items-center justify-center border border-dashed border-gray-400 h-[40%] p-4 text-center">
          <input {...getInputProps()} />
          <button 
            style={{ border: '1px dashed' }}
            className="bg-[#E6E6E6] px-3 py-1 text-[#595959] text-sm rounded-sm ml-1 mr-2 my-1">
              Browse
          </button>
                 </div>

        {/* Accepted formats */}
        <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
          Valid formats: pdf, png, jpg, jpeg
        </div>
     
        {/* Uploaded Signature File Name */}
        {uploadedSignature && (
          <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
            File Name: {uploadedSignature.name}
          </div>
        )}

        {/* Cancel and Confirm Buttons */}
        <div className="w-full mt-4 mx-auto gap-2 sm:w-full items-end flex justify-end">
          <button
            onClick={() => {
              setUploadedSignature(null);
              setfile('')
              setIsuploadmodal(false);
            }}
            className="w-[28%] h-10 bg-[#FFF] text-[#232222] border border-[#CBCBCB] text-sm font-semibold rounded-lg"
          >
            Cancel
          </button>
          <button
            disabled={!uploadedSignature}
            onClick={() => {
             handleSave()
            }}
            className={`w-[28%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg ${!uploadedSignature ? 'opacity-60 cursor-not-allowed' : ''}`}
          >
            Upload
          </button>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
    </div>
  );
};

export default UploadFileModal;
