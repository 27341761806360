import { useState } from 'react';
import logo from '../../assets/blinkscribe.png';

const DocPreview = ({ data }) => {
    const [showNotification, setShowNotification] = useState(data.length === 0); // Show notification if there is no data

    return (
        <div className="flex flex-col items-start w-full p-4 h-[100%] overflow-y-auto custom-scrollbar">
            <div className="flex items-center justify-center w-full">
                <img src={logo} alt="logo" className="h-10" />
            </div>

            {/* Horizontal line */}
            <div className="w-full mt-4">
                {/* <hr className="w-full border-t-2 border-gray-300 my-2" />
                <div className="text-xs font-normal [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                    Recording is too short for transcription. Please try again...
                </div> */}
                <hr className="w-full border-t-2 border-gray-300 my-2" />
            </div>

            {/* Notification Banner */}
            {showNotification && (
                <div className="bg-yellow-200 text-yellow-800 p-2 rounded my-2 w-full text-center">
                    No content available in the document.
                </div>
            )}

            {/* Render history texts */}
            <div className="flex flex-col items-start w-full mt-4">
                {data.length === 0 ? (
                    <p className="text-gray-500 text-sm text-center">No data available.</p>
                ) : (
                    data.map(item => (
                        <div key={item.id} className="flex items-center mb-2 p-2 w-full">
                            <h3 className="font-semibold text-sm mr-2 underline">{item.title.replace(/_/g, ' ')}:</h3>
                            <p className="text-sm text-gray-600  break-words overflow-hidden max-w-full">
                                {item.text || "No content available"}
                            </p>
                        </div>
                    ))
                )}
            </div>

            {/* Footer */}
            <div className="mt-6 w-full flex flex-col items-center">
                <div className="text-xs font-normal [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-center">
                    Generated By
                </div>
                <img src={logo} alt="logo" className="h-8" />
            </div>
        </div>
    );
}

export default DocPreview;
