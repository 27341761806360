import { useEffect, useState } from "react";
import usericon from '../../assets/user.png';
import CloseIcon from '@mui/icons-material/Close';
import useModalStore from "zustand/zustandstore";
import api from "api/api";

const AddPatientmodal = ({ isOpen,updateSelectedTemplate, close,handleSaveFromModal }) => {
const[templates,setTemplate]=useState([])
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { setModalValue } = useModalStore();
const [patientid,setPatientId]=useState('')
const [patientName,setPatientName]=useState('')
const [selectedTemplate, setSelectedTemplate] = useState('');
const mydata=useModalStore()
const fetchmydata=async()=>{
  const response = await api.get('/auth/me')
  if (response && response.templates && response.templates.default_template) {
             setSelectedTemplate(response.templates.default_template)
  }
  if (response && response.templates && response.templates.template_names) {
    setTemplate(response.templates.template_names);
}
}
useEffect(()=>{
fetchmydata()
},[])

 const handlesubmit = () => {
  if (!patientName) {
    setErrorMessage('Please enter patient name');
    return;
  }
  if (!/^[A-Za-z\s]+$/.test(patientName)) {
    setErrorMessage('Patient name should contain only Alphabets');
    return;
  }
  if (!patientid) {
    setErrorMessage('Please enter patient ID');
    return;
  }
  if (!/^\d+$/.test(patientid)) {
    setErrorMessage('Patient ID should be a number');
    return;
  }

  setModalValue({ patId: patientid, patName: patientName });
  // setModalValue({ isrecordingmodal: true });
  // setPatientId('');
  // setPatientName('');
  // close(false);
  updateSelectedTemplate(selectedTemplate)
  handleSaveFromModal(patientid,patientName)
   setPatientId('');
  setPatientName('');
};


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-4 sm:p-6 min-h-[50vh] rounded-[15px] shadow-lg w-[90%] sm:w-[70%] max-w-md relative flex flex-col">
      <button
        onClick={()=>{
          setPatientId('')
          setPatientName('')
          close(false)}}
        className="absolute top-2 right-2 text-gray-600 hover:text-black"
      >
        <CloseIcon/>
      </button>
      <div className="flex flex-col items-center mt-5 justify-between flex-grow">
        <div className="mb-4 text-center">
          <div className="font-bold text-[#1C2A3A] text-lg sm:text-xl leading-7">Add Patient Details</div>
        </div>
  
        <div className="relative mb-4 w-[80%] mx-auto">
          <input
            type="text"
            value={patientName}
            onChange={(e)=>{
              if(errorMessage){
                setErrorMessage('')
              }
            
              setPatientName(e.target.value)}}
            placeholder="Enter Patient Name"
            className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
          />
          <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
        </div>
        <div className="relative mb-4 w-[80%] mx-auto">
          <input
          value={patientid}
          onChange={(e)=>{
            if(errorMessage){
              setErrorMessage('')
            }
           
            setPatientId(e.target.value)}}
            type="text"
            placeholder="Enter Patient Id"
            className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
          />
          <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
        </div>
        {mydata.usertype === 'PLUS' && templates.length > 0 && (
  <div className="relative text-left mb-4 w-[80%] mx-auto">
    <select
      value={selectedTemplate}
      onChange={(e) => setSelectedTemplate(e.target.value)}
      className="w-full text-left bg-transparent h-[45px] border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal"
    >
     
      {templates.map((template, index) => (
        <option className="text-left" key={index} value={template}>
          {template}
        </option>
      ))}
    </select>
  </div>
)}

  
        {/* Error Message */}
        {errorMessage && (
          <div className="text-red-500 text-xs sm:text-sm mb-4 w-[80%] mx-auto">
            {errorMessage}
          </div>
        )}
  
               <div className="flex items-center justify-center mt-4 gap-5 w-full">
                  <button 
                  onClick={()=>handlesubmit()}             
                    className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
               Save
                  </button>
                  <button 
               onClick={()=>{
                setPatientId('')
                setPatientName('')
                close(false)}}
                    className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
              Cancel
                   </button>
      </div>
      </div>
    </div>
  </div>
  
  );
};

export default AddPatientmodal;
