import React, { useState } from "react";
import { Backdrop, Card, CardContent, CircularProgress, Snackbar } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe 4.svg";
import { useLocation, useNavigate } from "react-router-dom";
import arrowleft from '../../../assets/arrowleft.png';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import api from "../../../api/api";

const UpdatePassword = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState(""); // New state for OTP
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const { phoneNumber, country_code ,pin,nav} = location.state || {};
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  
  const handleCloseToast = () => {
      setOpenToast(false);
  };
  const handleSubmit = async () => {
    setError(""); // Clear previous errors
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    // if (!otp) {
    //   setError("OTP is required.");
    //   return;
    // }
    setIsLoading(true);
    let numb = parseInt(phoneNumber);
    
    try {
      const response = await api.put('/v1/users/forgot-password', {
        "country_code": country_code,
        "mobile_number": numb,
        "otp": pin, // Include OTP in the payload
        "new_password": newPassword,
        "confirm_password": confirmPassword
      });

      if (response.status === 200) {
        setToastMessage('Password updated successfully!');
        setOpenToast(true)
        setTimeout(() => {
          if(nav=='profile'){
            navigate('/manage-profile')
            return
          }else{
        navigate('/auth1')       
          }
          
        }, 3000);
        // Handle success (e.g., navigate or show a success message)
      }else if(response.status==201){
        setToastMessage(response.message);
        setOpenToast(true)
      }else{
        setToastMessage('something went wrong');
        setOpenToast(true)
      }

    } catch (err) {
      setError("An error occurred. Please try again."); // Handle API error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-around items-center min-h-screen h-auto p-0 md:p-6 sm:p-6">
        <Card className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg">
          <CardContent className="h-full p-6">
            <div className="flex items-start">
              <img
                onClick={() => navigate("/auth1")}
                src={arrowleft}
                alt="Back Arrow"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <div className="flex flex-col items-center justify-around h-full">
              <div className="flex justify-center mt-10">
                <img
                  src={blinkscribelogo}
                  alt="BlinkScribe Logo"
                  className="h-24 sm:h-16 md:h-16 w-30 sm:w-auto md:w-auto"
                />
              </div>
              <div>
                <div className="font-semibold text-[#1c2a3a] text-2xl md:text-xl sm:text-xl tracking-[0] leading-9">
                  Reset Password
                </div>
              </div>

              {/* OTP Input
              <div className='relative w-[100%] md:w-[60%] sm:w-[60%]'>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                  value={otp}
                  onChange={(e) => {
                    if (error) {
                      setError('');
                    }
                    setOtp(e.target.value);
                  }}
                />
              </div> */}

              {/* New Password Input */}
              <div className='relative w-[100%] md:w-[60%] sm:w-[60%]'>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                  value={newPassword}
                  onChange={(e) => {
                    if (error) {
                      setError('');
                    }
                    setNewPassword(e.target.value);
                  }}
                />
                <span
                  onClick={() => {
                    if (error) {
                      setError('');
                    }
                    setShowPassword(!showPassword);
                  }}
                  className="absolute right-3 top-3 cursor-pointer"
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
              </div>

              {/* Confirm Password Input */}
              <div className='relative w-[100%] md:w-[60%] sm:w-[60%]'>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Re-enter New Password"
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-3 cursor-pointer"
                >
                  {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
              </div>

              {/* Error Message */}
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}

              {/* Buttons */}
              <div className="flex flex-col items-center space-y-4 w-full mb-24 md:mb-0 sm:mb-0">
                <button
                  className="flex w-[90%] md:w-[60%] sm:w-[60%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
                  onClick={handleSubmit}
                >
                  <div className="font-semibold text-base">Submit</div>
                </button>
                <button
                  className="flex w-[90%] md:w-[60%] sm:w-[60%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
                  onClick={() => navigate("/auth1")}
                >
                  <div className="font-semibold text-base">Back to Login</div>
                </button>
              </div>

              <div>
                {/* <div className="font-semibold text-base text-center cursor-pointer text-gray-800">
                  Need Help?
                </div> */}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
                open={openToast}
                autoHideDuration={6000}
                onClose={handleCloseToast}
                message={toastMessage}
            />
    </>
  );
};

export default UpdatePassword;
