import React, { useState } from 'react';
import logo from '../../assets/blinkscribe 4.svg';

const AppOptions = () => {
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(null);

  // Handler to set selected option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  // Box shadow style for the selected option
  const selectedShadow = "shadow-[0px_0px_8px_8px_rgba(133,_182,_255,_0.35)]";

  return (
    <>
      {/* Overlay Background - Covers the entire screen */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-10 min-h-screen min-w-full"></div>

      {/* Overlay Card - Centered, updated styles */}
      <div className="flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[12.781px] 
      shadow-[0px_0px_8px_8px_rgba(0,_0,_0,_0.04)] p-6 px-12 min-w-[160px] h-full md:h-auto sm:h-auto overflow-auto w-full sm:w-[80%] md:w-[70%] min-h-[20px] z-20">
        <div className="md:w-[70%] sm:w-[80%]">
          <div className="flex justify-center mb-4 md:mt-0 sm:mt-0">
            <img
              src={logo}
              alt="BlinkScribe Logo"
              className="h-20 sm:h-14 md:h-14 w-24 sm:w-auto md:w-auto"
            />
          </div>

          <h2 className="text-lg font-semibold mb-4">Choose your Experience</h2>

          {/* Cards Container - Row direction on medium and larger screens */}
          <div className="flex flex-col md:flex-row gap-4 w-full">
            {/* Medical Card */}
            <div
              onClick={() => handleOptionClick('medical')}
              className={`flex-1 p-6 overflow-auto bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 'medical' ? selectedShadow : ''}`}
            >
              <h3 className="text-sm sm:text-lg font-semibold text-center">blinkScribe for Medical</h3>
              <ul className="mt-4 text-sm sm:text-md text-gray-600 space-y-2 list-disc pl-6">
                <li>
                  <span className="text-[#000] font-semibold">Speed and Accuracy</span>
                  <div>Real-time or near-instant transcription for medical professionals.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">HIPAA Compliance</span>
                  <div>Essential for security and privacy in healthcare.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">Voice Recognition for Medical Terms</span>
                  <div>Specialized for complex medical language.</div>
                </li>
              </ul>
            </div>

            {/* Meetings Card */}
            <div
              onClick={() => handleOptionClick('meetings')}
              className={`flex-1 p-6 overflow-auto bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 'meetings' ? selectedShadow : ''}`}
            >
              <h3 className="text-sm sm:text-lg text-center font-semibold">blinkScribe for Meetings</h3>
              <ul className="mt-4 text-sm sm:text-md text-gray-600 space-y-2 list-disc pl-6">
                <li>
                  <span className="text-[#000] font-semibold">Real-Time Transcription</span>
                  <div>For live conferences, meetings, and presentations.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">Multi-Speaker Identification</span>
                  <div>Helpful for panel discussions and large meetings.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">Searchable Transcripts</span>
                  <div>Making it easy to find key topics or speakers.</div>
                </li>
              </ul>
            </div>
          </div>

          {/* Additional Info */}
          <p className="text-black text-[14px] font-poppins italic font-light leading-normal tracking-[0.15px] mt-4">
            The experience can be modified in your profile settings after logging in.
          </p>

          {/* Continue Button */}
          <div className="flex items-center justify-center w-full mt-6">
            <button className="flex w-[100%] sm:w-[45%] md:w-[35%] h-12 md:h-10 sm:h-8 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white border border-neutral-500 transition duration-300 ease-in-out">
              <div className="font-semibold text-sm">Continue</div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppOptions;
