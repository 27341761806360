import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import ToastMessage from 'utils/muialerts/righttoptoast';

const PdfRender = ({ ispdfshow, setIspdfShow, pdfBlob,data}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const[loading,setLoading]=useState(false)
  const navigate=useNavigate()
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
 const [severity, setSeverity] = useState('success');
  const handleZoomIn = () => setZoomLevel(prev => prev + 0.1);
  const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.1));
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
const handleSignoff=async()=>{
  if(loading)return
  setLoading(true)
  try {
    const response= await api.put('/v1/document/sign-off',{"document_name":data?.audiolink})
    if(response.status===200){
      setMessage(response.message);
      setSeverity('success'); // Change severity as needed
      setOpen(true);
    }else if(response.status===201){
      setMessage(response.message);
      setSeverity('info'); // Change severity as needed
      setOpen(true);    
    }
  } catch (error) {
    console.log(error)
    setMessage('something went wrong');
    setSeverity('error'); // Change severity as needed
    setOpen(true);  
  }finally{
    setLoading(false)
  }

}

 if (!ispdfshow) return null;

  return (
    <div className="fixed flex flex-col inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
       {data?.transcriptionstatus==='Summarized'?
 <div className="p-4 mb-3  bg-white shadow-lg w-11/12 max-w-3xl relative rounded-lg gap-4 flex justify-center">
        <button
      onClick={()=>{handleSignoff()}}
      disabled={loading} // Disable the button during loading
      className={`px-4 py-2 min-w-lg text-white rounded-lg text-sm font-semibold transition-all duration-200 ${
        loading ? 'bg-gray-500 cursor-not-allowed opacity-50' : 'bg-[#1C2A3A] cursor-pointer opacity-100'
      }`}
    >
      {loading ? 'Signing Off...' : 'Sign Off'}
    </button>
          <button
            onClick={() =>{
              setMessage('');
       
              setOpen(false);
              setIspdfShow(false)}}
          className="px-4 py-2 min-w-lg bg-[#1C2A3A] text-white rounded-lg text-sm font-semibold">Cancel</button>
          <button
          onClick={()=>{ navigate(`/archive-edit/${data?.id}/${data?.patient_name}`);}}
          className="px-4 py-2 min-w-lg bg-[#1C2A3A] text-white rounded-lg text-sm font-semibold">Edit</button>
        </div> :<></>}
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl relative">
      
        <div className='flex items-center justify-between p-4'
          style={{ background: "#FFF", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.24)" }}
        >
          <div className='text-[#979797] font-sans'>
            SOAP NOTE
          </div>
          <div className="flex items-center gap-4">
            <button onClick={handleZoomOut} className= "px-2">-</button>
            <div className="mx-2 text-[#979797] font-sans">{Math.round(zoomLevel * 100)}%</div>
            <button onClick={handleZoomIn} className="px-2">+</button>
          </div>
          <button
            className="text-gray-600 hover:text-gray-800"
            onClick={() => {
              setMessage('');       
              setOpen(false);
              setIspdfShow(false)}}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="p-4" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={zoomLevel}
                renderTextLayer={false} // Add this line to disable text rendering
              />
            ))}
          </Document>
        </div>
        
      </div>
      <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
    </div>
  );
};

export default PdfRender;
