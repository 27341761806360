import AddPatientmodal from "basic/modals/addpatient";
import Basicnav from "./basicnav";
import BasicTable from "./basictable";
import BasicFilter from "./filters/basicfilter";
import { useEffect, useState } from "react";
import useModalStore from "zustand/zustandstore";
import redmic from '../../assets/redmic.png';
import api from "../../api/api";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSavedload, setSelectedTab } from "../../redux/store";
import BasicPdfRender from "basic/basicutils/basicpdfrender";
import ToastMessage from "utils/muialerts/righttoptoast";

const BasicDashboard = () => {
  const savedload = useSelector((state) => state.data.savedload);
  const [tab, setTab] = useState("ALL");
  const mydata = useModalStore();
  const [loading,setLoading]=useState(false)
  const mytabs=localStorage.getItem('mytab')
  const { setModalValue } = useModalStore();

  const[showpatientmodal,setPatientModal]=useState(false)
  const dispatch = useDispatch();
  const[dashboardData,setDashboardData]=useState([])
  const [currentTimestamp, setCurrentTimeStamp] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

let payload={
  "filter_array": {
    "date_filter": {
      start_date: currentTimestamp.startDate,
        end_date: currentTimestamp.endDate,
    }
  },
  "dash_filter": mytabs||"ALL",
}
const fetchdashboardData=async()=>{
  let obj=JSON.parse(JSON.stringify(payload))
  dispatch(setSavedload({ ...obj }));
  try {
    setLoading(true)
    console.log(mydata.usertype)
    const response = await api.get('/auth/me')
let apiresp;
    if (response && response.config && response.config.user_type) {

      if (response.config.user_type === 'BUSINESS') {
        apiresp=await api.post('/v1/business/recording/dashboard',payload)     

      } else {

        apiresp=await api.post('/v1/basic/recording/dashboard',payload)

      } 

    }
    

if(apiresp.status===200){

setDashboardData(apiresp.data)
}else {
setDashboardData([])
}
  } catch (error) {
    console.log(error)
  }finally{
    setLoading(false)
  }


}

useEffect(()=>{
  if(mydata?.trigger){
    fetchdashboardData()
  }
},[mydata?.trigger])

useEffect(() => {
  if (currentTimestamp.startDate) {
    fetchdashboardData();
  }

}, [currentTimestamp]);
useEffect(()=>{
  setTab(mytabs)
  },[])
  const handleScribeFilter = async(patient_id) => {
    if (!patient_id) {
      return;
    }
    let obj=JSON.parse(JSON.stringify(payload))
    obj.search_filter = `${patient_id}`;
    try {
      setLoading(true)
      const endpoint = mydata.usertype === "BUSINESS" 
  ? '/v1/business/recording/dashboard' 
  : '/v1/basic/recording/dashboard';
      // const response=await api.post('/v1/basic/recording/dashboard',obj)
      const response = await api.post(endpoint, obj);
  if(response.status===200){  
  setDashboardData(response.data)
  }else {
  setDashboardData([])
  }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  
  };
const handleTabs = (x) => {
  setTab(x);
  localStorage.setItem('mytab',x)
  payload.dash_filter = x; 
  dispatch(setSelectedTab('PENDING'))
fetchdashboardData()
 // payload.dash_filter = "ALL";
};
const handletimeStamp = (stamp) => {
  setCurrentTimeStamp(stamp);
};
const stampformater = async () => {
  const today = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
  const startTime = "07:00:00.000Z"; // Initial start time
  const endTime = "19:00:00.000Z"; // Initial end time
  const dateFilter = savedload?.filter_array?.date_filter;
  if (
    !dateFilter ||
    !dateFilter.start_date ||
    !dateFilter.end_date 
     ) {
    setCurrentTimeStamp({
      startDate: today,
      endDate: today,
      startTime: startTime,
      endTime: endTime,
    });
  } else {
    setCurrentTimeStamp({
      startDate: dateFilter.start_date,
      endDate: dateFilter.end_date,
      startTime: dateFilter.start_time,
      endTime: dateFilter.end_time,
    });
  }
};

useEffect(() => {
  stampformater();
}, []);
useEffect(() => {
  payload = savedload;  
}, []);

  return (<>
    <div className="flex flex-col min-h-[80vh] gap-8 justify-start items-start p-2 px-2 sm:px-20 md:px-20">
      <div className="w-full">
        <Basicnav />
      </div>
      <div className="w-full">
        <BasicFilter handleScribeFilter={handleScribeFilter} handletimeStamp={handletimeStamp} payload={payload.filter_array}/>
      </div>
      <div className="w-full flex justify-between items-center flex-col gap-4 sm:flex-row sm:gap-0 mt-1">
        <div className="flex flex-wrap justify-center md:justify-start items-center gap-4">
          <button
          onClick={() => handleTabs("ALL")}
            className={`w-[90%] sm:w-[230px] md:w-[230px] h-[45px] sm:h-10 md:h-10 ${
              tab === "ALL" ? "bg-[#1c2a3a] text-white" : "bg-white text-black"
            } text-sm rounded-[10px] flex items-center justify-center shadow-[0px_4px_4px_#00000040]`}
          >
            All Patients
          </button>
          <button
            onClick={() => handleTabs("PENDING")}
            className={`w-[90%] sm:w-[230px] md:w-[230px] h-[45px] sm:h-10 md:h-10 ${
              tab === "PENDING"
                ? "bg-[#1c2a3a] text-white"
                : "bg-white text-black"
            } text-sm rounded-[10px] flex items-center justify-center shadow-[0px_4px_4px_#00000040]`}
          >
            Pending
          </button>
          <button
               onClick={() => handleTabs("COMPLETED")}
            className={`w-[90%] sm:w-[230px] md:w-[230px] h-[45px] sm:h-10 md:h-10 ${
              tab === "COMPLETED"
                ? "bg-[#1c2a3a] text-white"
                : "bg-white text-black"
            } text-sm rounded-[10px] flex items-center justify-center shadow-[0px_4px_4px_#00000040]`}
          >
            Completed
          </button>
        </div>

        {!mydata?.isrecordingmodal ? (
          <div className="flex items-center gap-3">
            <div
              className="select-none text-sm md:text-base font-semibold"
              style={{ fontStyle: "normal", color: "#575860" }}
            >
              Start Recording
            </div>
            <div
              onClick={() => {
            setPatientModal(true)
                setModalValue({ isrecordingmodal: true });
              }}
              className="relative flex cursor-pointer items-center justify-center"
            >
              <img
                src={redmic}
                className="w-[40px] h-[40px] md:w-[50px] md:h-[50px]"
                alt="Mic"
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="w-full">
        <BasicTable 
        fetchdashboardData={fetchdashboardData}
        data={dashboardData} handletimeStamp={handletimeStamp} />
      </div>
      {/* <AddPatientmodal  isOpen={showpatientmodal} close={setPatientModal} /> */}
    </div>

    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>

    </>
  );
};

export default BasicDashboard;
