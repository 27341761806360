import calendarIcon from "../../assets/calendar-2.svg";
import greentick from "../../assets/righttick.svg";
import arrowleft from "../../assets/arrowleft.png";
import profilering from "../../assets/profilering.png";
import face from "../../assets/profile.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MainHeader from "header/header";
import api from "../../api/api";
import { formatDate } from "utils/date";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { formatDateToYMD } from "utils/date";
import { decodeTimeToISO } from "utils/date";
import { NavigateNext } from "@mui/icons-material";
// import PdfRender from "./pdfrender";
import ToastMessage from "utils/muialerts/righttoptoast";
import Ring from "pages/landing/ring";
import SimpleModal from "alerts/recordingscreen/simplemodal";
import { FaInfoCircle } from "react-icons/fa";
import PdfRender from "pages/recording/pdfrender";
import BasicPdfRender from "basic/basicutils/basicpdfrender";
const BasicArchive = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const location = useLocation();
  const { ts,profile_photo } = location.state || {};
  const navigate = useNavigate();
  const[showinfo,setShowInfo]=useState(false)
  const[currentobj,setcurrentobj]=useState({})
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [today, setToday] = useState("");
  const [isPickerOpen, setIsPickerOpen] = useState(false); // State to toggle DatePickerWithSlider
  const [datestate, setDateState] = useState([
    { startDate: new Date(ts), endDate: new Date(ts), key: "selection" },
  ]);
  const [pdffile,setPdffile]=useState()
  const [value, setValue] = useState([420, 1140]); // Example: 10:00 AM to 12:00 PM
  const [showdate, setShowdate] = useState("");
  const [ispdfshow,setIspdfShow]=useState(false)
  const[mycard,setMyCard]=useState('')
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const[myimgurl,setMyimgUrl]=useState(false)
  function checkImage(url) {
    const img = new Image();
    img.onload = function() {
        console.log("Image loaded successfully");
        setMyimgUrl(true)
    };
    img.onerror = function() {
        console.log("Failed to load image");
        setMyimgUrl(false)
    };
    img.src = url;
  }
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
  useEffect(() => {
    const date = new Date(ts); // Convert to a JavaScript Date object
    // Format the date as: "Monday, September 23, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setShowdate(formattedDate);
  }, [ts]);
  const { id } = useParams();
  const handlepdf = async (value,card) => {

    if(card.transcriptionstatus=='Processing'){
      setMessage('Update in process. Hold on!');
      setSeverity('info'); // Change severity as needed
      setOpen(true);
      return
    }

    try {
      // Trigger loading spinner
      setIsLoading(true);

      const uniqueParam = new Date().getTime(); // or use a random number: Math.random()

    // Fetch the PDF from the server with a unique parameter to prevent caching
    const response = await api.get(`/v1/basic/documents/PDF/${value}?_=${uniqueParam}`, {
      responseType: "blob", // Ensure response type is 'blob' for binary data
    });
       const pdfBlob = new Blob([response], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setMyCard(card)
      setPdffile(pdfBlob)
      // Open the generated URL in a new tab
      //window.open(pdfUrl, "_blank");

    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      // Turn off loading spinner
      setIsLoading(false);
      setIspdfShow(true)
    }
  };

  let payload = {
    filter_array: {
      date_filter: {
        startTime: decodeTimeToISO(value[0]),
        endTime: decodeTimeToISO(value[1]),
      },
    },
 
  };

  const fetchdata = async () => {
    setIsLoading(true);
    try {
      let response = await api.post(`/v1/basic/patient/${id}/recording/details`,payload
      );    
      if (response.status == 200) {
        console.log(response?.data)
        setData(response?.data);
      } 
    
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timestamp = new Date(ts);
    const yy_mm_dd = timestamp.toISOString().slice(2, 10).replace(/-/g, "-");
   // payload.start_date = yy_mm_dd;
    fetchdata();
    checkImage(profile_photo)
  }, []);
  useEffect(() => {
    const sorted = [...data].sort((a, b) => new Date(a.ts) - new Date(b.ts));
    setSortedData(data);
  }, [data]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Number of items to display per page

  const indexOfLastData = currentPage * itemsPerPage; // Last index of current page
  const indexOfFirstData = indexOfLastData - itemsPerPage; // First index of current page
  const currentData = sortedData.slice(indexOfFirstData, indexOfLastData); // Get current page data
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <MainHeader />
      <div
        className={`flex ${
          isPickerOpen ? "md:items-start min-h-[100vh]" : "items-center"
        } items-center justify-center w-full h-screen sm:h-auto md:h-auto`}
      >
        <div className="w-[95%] md:w-[428px]  h-min-[50px] h-auto md:mt-10   p-6  bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040]">
          <div className="flex items-start ">
            <img
              onClick={() => {
                navigate(-1);
              
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="relative flex items-center justify-center mr-4">
  {myimgurl ? (
    <>
      <img src={profile_photo} 
      className={`${isRecording ? "w-[60px] h-[60px]" : "w-[100px] h-[95px]"} rounded-full`}
      alt="Profile" />
    </>
  ) : (
    <>
      <img
        src={profilering}
        alt="Profilering"
        className={`${isRecording ? "w-[60px] h-[60px]" : "w-[90px] h-[85px]"}`}
      />
      <img
        src={face}
        alt="Face"
        className={`absolute ${isRecording ? "w-[40px] h-[60px]" : "w-[59px] h-[80px]"}`}
      />
    </>
  )}
</div>

          <div className=" w-full flex justify-center  items-center mt-4">
            <div className="[font-family:'Poppins-Regular',Helvetica] text-center cursor-pointer font-semibold text-[#000000] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
              Archive
            </div>

            <div className="flex items-center gap-5">
        
            </div>
          </div>

        
          <div
            className="w-max-lg custom-scrollbar gap-4 flex flex-col  justify-center items-center"
            style={{
              height: "300px", // Set the desired height for the scroll area
              overflowY: "auto", // Enable vertical scrolling when content exceeds height
            }}
          >
         {currentData && currentData.length > 0 ? (
  currentData.map((card, index) => (
    <div
      key={index}
      onClick={() => {
        if (card.transcriptionstatus === 'Processing') {
          setMessage('Update in process. Hold on!');
          setSeverity('info'); // Change severity as needed
          setOpen(true);
          return;
        }else{
        handlepdf(card.audiolink.split(".")[0],card,)
        }
       
      }}
      className="flex items-center justify-between cursor-pointer p-6 bg-white shadow-md rounded-[10px] w-[350px] h-10 relative"
      style={{ boxShadow: "0 1px 4px rgba(0, 0, 0, 0.25)" }}
    >
    

      <div className="flex items-center">
        <div className="flex items-end relative">
          <img
            src={calendarIcon}
            alt="Calendar Icon"
            className="w-5 h-5"
          />
          <img
            src={greentick}
            alt="Green Tick"
            className="w-3 h-2 relative right-1 bottom-1"
          />
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="[font-family:'Poppins-Regular',Helvetica] w-[150px] text-left font-normal text-[#262629] text-xs tracking-[0] leading-[normal]"
        >
          {formatDate(card.ts)}
        </div>
      </div>
      {card.transcriptionstatus !== 'Processing' ?<div
       className="w-5 h-5"
        onClick={() =>{
          if (card.transcriptionstatus === 'Processing') {
            setMessage('Update in process. Hold on!');
            setSeverity('info');
            setOpen(true);
            return;
          }

          navigate(`/basic-archive-edit/${card.id}/${card.patient_name}`)
        }
        }
        style={{ cursor: "pointer" }}
      >
        <EditNoteIcon />
      </div>:<>{card?.transcriptionstatus === 'Processing' &&(<div className="w-7 h-7">
        <Ring value={Math.floor(card?.handling_time)} />
        </div>)}</>}



      <div className="text-sm">{card.time}</div>
    </div>
  ))
) : (
  <div>No data found</div>
)}

          </div>
        {currentData && currentData.length > 0? <div className="flex w-full items-center justify-center"> <div onClick={handlePreviousPage} disabled={currentPage === 1}><NavigateBeforeIcon/></div> 
          {currentPage}/{totalPages}
          
           <div onClick={handleNextPage} disabled={currentPage === totalPages}><NavigateNext/></div></div>:<></>}
          
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SimpleModal isModalOpen={showinfo} setModalOpen={setShowInfo} data={currentobj}/>
      <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
      <BasicPdfRender ispdfshow={ispdfshow}setIspdfShow={setIspdfShow} pdfBlob={pdffile} data={mycard}/>
    </>
  );
};

export default BasicArchive;
