import calendarIcon from "../../assets/calendar-2.svg";
import greentick from "../../assets/righttick.svg";
import pdf from "../../assets/XMLID_2268_.svg";
import dummypdf from "../../assets/svgs/dummypdf.pdf";
import arrowleft from "../../assets/arrowleft.png";
import profilering from "../../assets/profilering.png";
import face from "../../assets/profile.png";
import filterDark from "../../assets/Filter-Fill.svg";
import CalendarDark from "../../../src/assets/calendar icon.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MainHeader from "header/header";
import api from "../../api/api";
import { formatDate } from "utils/date";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DatePicker from "./Datepicker";
import { formatDateToYMD } from "utils/date";
import { decodeTimeToISO } from "utils/date";
import { NavigateNext } from "@mui/icons-material";
import PdfRender from "./pdfrender";
import ToastMessage from "utils/muialerts/righttoptoast";
import Ring from "pages/landing/ring";
import SimpleModal from "alerts/recordingscreen/simplemodal";
import { FaInfoCircle } from "react-icons/fa";
const Archive = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const location = useLocation();
  const { ts,profile_photo } = location.state || {};
  const navigate = useNavigate();
  const[showinfo,setShowInfo]=useState(false)
  const[currentobj,setcurrentobj]=useState({})
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [today, setToday] = useState("");
  const [isPickerOpen, setIsPickerOpen] = useState(false); // State to toggle DatePickerWithSlider
  const [datestate, setDateState] = useState([
    { startDate: new Date(ts), endDate: new Date(ts), key: "selection" },
  ]);
  const [pdffile,setPdffile]=useState()
  const [value, setValue] = useState([420, 1140]); // Example: 10:00 AM to 12:00 PM
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [uniqueVisitType, SetUniqueVisitType] = useState([]);
  const [uniqueProvider, setUniqueProvider] = useState([]);
  const [showdate, setShowdate] = useState("");
  const [ispdfshow,setIspdfShow]=useState(false)
  const[mycard,setMyCard]=useState('')
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const[myimgurl,setMyimgUrl]=useState(false)
  function checkImage(url) {
    const img = new Image();
    img.onload = function() {
        console.log("Image loaded successfully");
        setMyimgUrl(true)
    };
    img.onerror = function() {
        console.log("Failed to load image");
        setMyimgUrl(false)
    };
    img.src = url;
  }
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};
  useEffect(() => {
    const date = new Date(ts); // Convert to a JavaScript Date object
    // Format the date as: "Monday, September 23, 2024"
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setShowdate(formattedDate);
  }, [ts]);
  const { id } = useParams();
  const handlepdf = async (value,card) => {

    if(card.transcriptionstatus=='Processing'){
      setMessage('Update in process. Hold on!');
      setSeverity('info'); // Change severity as needed
      setOpen(true);
      return
    }

    try {
      // Trigger loading spinner
      setIsLoading(true);

      const uniqueParam = new Date().getTime(); // or use a random number: Math.random()

    // Fetch the PDF from the server with a unique parameter to prevent caching
    const response = await api.get(`/v1/documents/PDF/${value}?_=${uniqueParam}`, {
      responseType: "blob", // Ensure response type is 'blob' for binary data
    });
      // Fetch the PDF from the server
      // const response = await api.get(`/v1/documents/PDF/${value}`, {
      //   responseType: "blob", // Ensure response type is 'blob' for binary data
      // });
      // // Create a Blob from the PDF data
      const pdfBlob = new Blob([response], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setMyCard(card)
      setPdffile(pdfBlob)
      // Open the generated URL in a new tab
      //window.open(pdfUrl, "_blank");

    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      // Turn off loading spinner
      setIsLoading(false);
      setIspdfShow(true)
    }
  };

  let payload = {
    filter_array: {
      date_filter: {
        startTime: decodeTimeToISO(value[0]),
        endTime: decodeTimeToISO(value[1]),
      },
    },
 
  };

  const fetchdata = async () => {
    setIsLoading(true);
    try {
      let response = await api.post(
        `/v1/patient/${id}/recording/details`,
        payload
      );    
      if (response.status == 200) {
        console.log(response?.data)
        setData(response?.data);
      } 
    
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timestamp = new Date(ts);
    const yy_mm_dd = timestamp.toISOString().slice(2, 10).replace(/-/g, "-");
   // payload.start_date = yy_mm_dd;
    fetchdata();
    checkImage(profile_photo)
  }, []);
  useEffect(() => {
    const sorted = [...data].sort((a, b) => new Date(a.ts) - new Date(b.ts));
    setSortedData(data);
  }, [data]);
  const handleVisitClick = (visit) => {
    setSelectedVisits(
      (prev) =>
        prev.includes(visit)
          ? prev.filter((item) => item !== visit) // Remove if already selected
          : [...prev, visit] // Add if not selected
    );
  };
  const handleProviderClick = (provider) => {
    setSelectedProviders((prev) =>
      prev.includes(provider)
        ? prev.filter((item) => item !== provider)
        : [...prev, provider]
    );
  };
  const toggleDatePicker = () => {
    !isPickerOpen && setIsPickerOpen(true);
  };
  const handledateclose = () => {
    const { startDate, endDate } = datestate [0];

    // Check if startDate and endDate are not the same
    if (startDate.getTime() !== endDate.getTime()) {
      payload.filter_array.date_filter.end_date = formatDateToYMD(
        datestate[0].endDate
      );
    } else {
      delete payload.filter_array.date_filter.end_date;
    }
    payload.filter_array.date_filter.startTime = decodeTimeToISO(value[0]);
    payload.filter_array.date_filter.endTime = decodeTimeToISO(value[1]);

    fetchdata();
    setIsPickerOpen(!true);
  };
  const handleDateChange = (newRange) => {
    setDateState(newRange);
  };
  useEffect(() => {
    SetUniqueVisitType([
      ...new Set(data.map((record) => record.purpose.toUpperCase())),
    ]);
    setUniqueProvider([
      ...new Set(data.map((record) => record.providername.toUpperCase())),
    ]);
  }, [data]);
  const handlefilterclose = () => {
    if (selectedVisits.length) {
      payload.filter_array.purpose = selectedVisits;
    } else {
      delete payload.filter_array.purpose;
    }
    if (selectedProviders.length) {
      payload.filter_array.providername = selectedProviders;
    } else {
      delete payload.filter_array.providername;
    }
    fetchdata();
    setIsFilterOpen(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4; // Number of items to display per page

  const indexOfLastData = currentPage * itemsPerPage; // Last index of current page
  const indexOfFirstData = indexOfLastData - itemsPerPage; // First index of current page
  const currentData = sortedData.slice(indexOfFirstData, indexOfLastData); // Get current page data
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <MainHeader />
      <div
        className={`flex ${
          isPickerOpen ? "md:items-start min-h-[100vh]" : "items-center"
        } items-center justify-center w-full h-screen sm:h-auto md:h-auto`}
      >
        <div className="w-[95%] md:w-[428px]  h-min-[50px] h-auto md:mt-10   p-6  bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040]">
          <div className="flex items-start ">
            <img
              onClick={() => {
                navigate(-1);
              
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="relative flex items-center justify-center mr-4">
  {myimgurl ? (
    <>
      <img src={profile_photo} 
      className={`${isRecording ? "w-[60px] h-[60px]" : "w-[100px] h-[95px]"} rounded-full`}
      alt="Profile" />
    </>
  ) : (
    <>
      <img
        src={profilering}
        alt="Profilering"
        className={`${isRecording ? "w-[60px] h-[60px]" : "w-[90px] h-[85px]"}`}
      />
      <img
        src={face}
        alt="Face"
        className={`absolute ${isRecording ? "w-[40px] h-[60px]" : "w-[59px] h-[80px]"}`}
      />
    </>
  )}
</div>

          <div className=" w-full flex justify-center  items-center mt-4">
            <div className="[font-family:'Poppins-Regular',Helvetica] text-center cursor-pointer font-semibold text-[#000000] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
              Archive
            </div>

            <div className="flex items-center gap-5">
              {/* <div className="font-semibold text-sm sm:text-base md:text-lg items-center font-poppins text-[#121212] tracking-normal leading-[0.1px]">
  {showdate}
</div> */}

              {/* <div className="flex items-center relative">
                <div
                  className="flex items-center"
                  onClick={toggleDatePicker}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={CalendarDark}
                    alt="Calendar Icon"
                  />
                </div>

              
                {isPickerOpen && (
                  <div className="absolute md:top-6 top-6 z-20 sm:top-6 right-[-450%] md:right-0 ">
                    <DatePicker
                      handleDateClose={handledateclose}
                      datestate={datestate}
                      handledateChange={handleDateChange}
                      value={value}
                      setValue={setValue}
                      setIsPickerOpen={setIsPickerOpen}
                    />
                  </div>
                )}
              </div> */}

        
              {/* <div className="flex items-center ">
                <img
                  style={{ width: "25px", height: "25px", cursor: "pointer" }}
                  src={filterDark}
                  alt="Filter Icon"
                  onClick={() => setIsFilterOpen(true)} 
                />
              
                {isFilterOpen && (
                  <div className="flex flex-col gap-8 items-start absolute top-[50%]  md:top-[30%] lg:top-[42%] right-[5%] md:right-[21%] lg:right-[38%]    w-[90%] md:w-full max-w-[450px] bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                    <div className="flex justify-between w-full items-center">
                      <div className="[font-family:'Poppins-Medium',Helvetica] font-bold text-black text-base text-center tracking-[0.16px] leading-[normal]">
                        Filter
                      </div>
                      <div>
                       
                        <div
                          onClick={() => {
                            setSelectedProviders([]);
                            setSelectedVisits([]);
                            setIsFilterOpen(false);
                          }} 
                        >
                          <CloseIcon
                            style={{ color: "#B4B4B4", cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>

              
                    <div className="w-full flex flex-col items-start justify-start">
                      <div
                        style={{ fontWeight: 550, fontStyle: "Tahoma" }}
                        className="text-black text-[14px] tracking-[0.16px] leading-[normal]"
                      >
                        Provider
                      </div>
                    
                      <div className="flex flex-wrap gap-2 mt-8">
                        {uniqueProvider.length > 0 ? (
                          uniqueProvider.map((x, index) => (
                            <button
                              key={index}
                              className={`w-auto p-2 h-8 rounded-[14px]  shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 ${
                                selectedProviders.includes(x)
                                  ? "bg-black text-white"
                                  : "bg-white text-black border border-gray-300"
                              }`}
                              onClick={() => handleProviderClick(x)}
                            >
                              {x}
                            </button>
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </div>
                    </div>

               
                    <div className="w-full flex flex-col items-start justify-start">
                      <div
                        style={{ fontWeight: 550, fontStyle: "Tahoma" }}
                        className="font-medium text-black text-[14px] tracking-[0.16px] leading-[normal]"
                      >
                        Visit Type
                      </div>
                    
                      <div className="flex flex-wrap gap-2 mt-8">
                        {uniqueVisitType.length > 0 ? (
                          uniqueVisitType.map((x, index) => (
                            <button
                              key={index}
                              className={`w-auto p-2 h-8 rounded-[14px]  shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 ${
                                selectedVisits.includes(x)
                                  ? "bg-black text-white"
                                  : "bg-white text-black border border-gray-300"
                              }`}
                              onClick={() => handleVisitClick(x)}
                            >
                              {x}
                            </button>
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </div>
                    </div>
                    <div className="flex mt-5 items-center justify-center w-full">
                      <button
                        className={`p-2 h-8 w-[40%] rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-white text-black border border-gray-300`}
                        onClick={(e) => {
                          e.target.classList.toggle("bg-black");
                          e.target.classList.toggle("text-white");
                          e.target.classList.toggle("bg-white");
                          e.target.classList.toggle("text-black");
                          handlefilterclose();
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </div>

        
          <div
            className="w-max-lg custom-scrollbar gap-4 flex flex-col  justify-center items-center"
            style={{
              height: "300px", // Set the desired height for the scroll area
              overflowY: "auto", // Enable vertical scrolling when content exceeds height
            }}
          >
         {currentData && currentData.length > 0 ? (
  currentData.map((card, index) => (
    <div
      key={index}
      onClick={() => {
        if (card.transcriptionstatus === 'Processing') {
          setMessage('Update in process. Hold on!');
          setSeverity('info'); // Change severity as needed
          setOpen(true);
          return;
        }else{
        handlepdf(card.audiolink.split(".")[0],card,)
        }
       
      }}
      className="flex items-center justify-between cursor-pointer p-6 bg-white shadow-md rounded-[10px] w-[350px] h-10 relative"
      style={{ boxShadow: "0 1px 4px rgba(0, 0, 0, 0.25)" }}
    >
    

      <div className="flex items-center">
        <div className="flex items-end relative">
          <img
            src={calendarIcon}
            alt="Calendar Icon"
            className="w-5 h-5"
          />
          <img
            src={greentick}
            alt="Green Tick"
            className="w-3 h-2 relative right-1 bottom-1"
          />
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="[font-family:'Poppins-Regular',Helvetica] w-[150px] text-left font-normal text-[#262629] text-xs tracking-[0] leading-[normal]"
        >
          {formatDate(card.ts)}
        </div>
      </div>
      {card.transcriptionstatus !== 'Processing' ?<div
       className="w-5 h-5"
        onClick={() =>{
          if (card.transcriptionstatus === 'Processing') {
            setMessage('Update in process. Hold on!');
            setSeverity('info');
            setOpen(true);
            return;
          }

          navigate(`/archive-edit/${card.id}/${card.patient_name}`)
        }
        }
        style={{ cursor: "pointer" }}
      >
        <EditNoteIcon />
      </div>:<>{card?.transcriptionstatus === 'Processing' &&(<div className="w-7 h-7">
        <Ring value={Math.floor(card?.handling_time)} />
        </div>)}</>}

        {
 (card.transcriptionstatus === 'APPROVED'|| card?.emr_posted_status) ? (
   (card.transcriptionstatus === 'APPROVED' ||(card?.handling_time === 100.0 && card.transcriptionstatus === 'Summarized')) ? (
      <>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setcurrentobj(card)
            setShowInfo(true);
          }}
        >
          {
            Object.values(card?.emr_posted_status).every(status => status === true) ? (
              <FaInfoCircle className="text-green-500 w-5 h-5 cursor-pointer" />
            ) : (
              <FaInfoCircle className="text-red-500 w-5 h-5 cursor-pointer" />
            )
          }
        </div>

            </>
    ) : (
      <div className="w-5 h-5"></div>
    )
  ) : (
    <div className="w-5 h-5"></div>
  )
}


      <div className="text-sm">{card.time}</div>
    </div>
  ))
) : (
  <div>No data found</div>
)}

          </div>
        {currentData && currentData.length > 0? <div className="flex w-full items-center justify-center"> <div onClick={handlePreviousPage} disabled={currentPage === 1}><NavigateBeforeIcon/></div> 
          {currentPage}/{totalPages}
          
           <div onClick={handleNextPage} disabled={currentPage === totalPages}><NavigateNext/></div></div>:<></>}
          
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SimpleModal isModalOpen={showinfo} setModalOpen={setShowInfo} data={currentobj}/>
      <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
      <PdfRender ispdfshow={ispdfshow}setIspdfShow={setIspdfShow} pdfBlob={pdffile} data={mycard}/>
    </>
  );
};

export default Archive;
