import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React from "react";
 
export default function Ring({ value }) {
  let progress = value;
 
  return (
    <div className="flex items-start justify-start h-auto  ">
      <div className="w-10 h-10 "> {/* w-8 and h-8 are Tailwind equivalents of 30px */}
        <div className=" w-full h-full">
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            styles={buildStyles({
              textSize: "28px", 
              textWeight: "700 !important",
              fontWeight: "900 !important",
              pathColor: "#1C2A3A",
              textColor: "#262629",
              trailColor: "#ddd",              
             
            })}
          />
        </div>
      </div>
    </div>
  );
}