import React, { useEffect, useState } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import RangeSlider from '../../../pages/landing/rangeslider'; // Assuming you have a RangeSlider component
import Box from '@mui/material/Box';
import Calendar from 'react-calendar'; // Import the calendar component
import 'react-calendar/dist/Calendar.css'; // Import the calendar styles
import { useDispatch, useSelector } from 'react-redux';
import { formatDateToYMD } from 'utils/date';
import { decodeTimeToISO } from 'utils/date';
import { setSavedload, setProviders, setVisittype } from "../../../redux/store";
import { parseISO, startOfDay } from 'date-fns'; // Import from date-fns
import { toZonedTime } from 'date-fns-tz'; // Only if you need this for timezone
import { Tooltip } from '@mui/material';
const storedDate=(x)=>{  
  let normaldate=[{
    startDate: new Date(),
    endDate: new Date(), 
    key: "selection",
  }]
   const dateFilter = x?.filter_array?.date_filter;   
   if (!dateFilter || !dateFilter.start_date || !dateFilter.end_date) {  

    return normaldate
  }else{

   let changed=[{
    startDate: startOfDay(parseISO(dateFilter.start_date)), // Use parseISO from date-fns
    endDate: startOfDay(parseISO(dateFilter.end_date)), 
    key: "selection",
  }]
    return changed
  }
}
const convertTimeToValue = (timeString) => {
    if(!timeString){
      return 420
    }
    // Extract hours and minutes from the time string
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
  
    // Convert to total minutes
    return hours * 60 + minutes;
  };
const Filtersdt = ({handletimeStamp,}) => {
    const savedload = useSelector((state) => state.data.savedload);
     const [showDatePicker, setShowDatePicker] = useState(false);
      const [value, setValue] = useState([420, 1140]);
       const [datestate, setdateState] = useState(storedDate(savedload));
    const dispatch = useDispatch();
    const formatDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        return `${day}, ${dayOfMonth} ${month}`;
    };
 
const handleOnDateSelector=(x)=>{
  let normaldate=[{
    startDate: new Date(),
    endDate: new Date(), 
    key: "selection",
  }]
normaldate[0].startDate=x;
normaldate[0].endDate=x 
setdateState(normaldate) 
}
    // useEffect(()=>{
    //     if(savedload.filter_array?.date_filter.start_time){
    //      setValue([convertTimeToValue(savedload.filter_array?.date_filter.start_time),convertTimeToValue(savedload.filter_array?.date_filter.end_time)])
    //     }       
    
    //   },[savedload])
       
    const changeDate = (days) => { 
      const newDate = new Date(datestate[0].startDate);
        newDate.setDate(newDate.getDate() + days);
        let normaldate=[{
          startDate: new Date(),
          endDate: new Date(), 
          key: "selection",
        }]
      normaldate[0].startDate=newDate;
      normaldate[0].endDate=newDate;        
        setdateState(normaldate)
        handletimeStamp({
          startDate:formatDateToYMD(newDate),
          endDate:formatDateToYMD(newDate),
          startTime:decodeTimeToISO(value[0]),
          endTime:decodeTimeToISO(value[1])
         })
      
    };

    const handleDateClick = () => {
        setShowDatePicker(!showDatePicker);
    };

    const dateOnCloseHandler = () => {
     setdateState(storedDate(savedload)) 
     if(savedload.filter_array?.date_filter.start_time){
      setValue([convertTimeToValue(savedload.filter_array?.date_filter.start_time),convertTimeToValue(savedload.filter_array?.date_filter.end_time)])
     }  
        setShowDatePicker(false);
    };

    
    const dateOnCloseHandle=()=>{     
        handletimeStamp({
      startDate:formatDateToYMD(datestate[0].startDate),
      endDate:formatDateToYMD(datestate[0].startDate),
      startTime:decodeTimeToISO(value[0]),
      endTime:decodeTimeToISO(value[1])
     })

     setShowDatePicker(false);
   
    }
    useEffect(()=>{
      setdateState(storedDate(savedload))
  
  },[savedload])


const handledateClear=()=>{
  handletimeStamp({
    startDate:new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    startTime: decodeTimeToISO(value[0]),
    endTime: decodeTimeToISO(value[1]),
  });
  setShowDatePicker(false);
}



    return (
        <div className='relative'>
            <div className='flex items-center gap-2'>
              <div onClick={() => changeDate(-1)} className="mx-2" style={{ fontSize: "25px", cursor: "pointer" }}>&lt;</div>
                <div
                    className='p-2 bg-[#FFF] cursor-pointer border w-auto min-w-48'
                    onClick={handleDateClick}
                    style={{
                        borderRadius: "18px",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                    }}
                >
                    <span className="text-center [font-family:'Poppins-Regular',Helvetica]  text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                        {formatDate(datestate[0].startDate)}
                    </span>
                </div>
                <div onClick={() => changeDate(1)} className="mx-2" style={{ fontSize: "25px", cursor: "pointer" }}>&gt;</div>
          
            </div>

            {/* Backdrop for dark overlay for date picker */}
            {showDatePicker && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={dateOnCloseHandler} />
            )}

            {/* Modal for date picker */}
            {showDatePicker && (
                <div className="absolute top-[110%] left-[0%] right-[5%] sm:right-0 lg:left-[13%] sm:w-full z-20 bg-white p-4 rounded-lg shadow-lg">
                    <div className='flex justify-between items-center'>
                        <div className="[font-family:'Poppins-Medium',Helvetica] font-bold text-black text-base tracking-[0.16px] leading-[normal]">
                            Calendar
                        </div>
                        <div className='flex items-center gap-2'>
                            <button onClick={()=>{
                              // handleApplyClick()
                              dateOnCloseHandle()
                            }} 
                           className={`p-2 h-8 w-16 rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-black text-white border border-gray-300`}>
                                Apply
                            </button>
                            <button onClick={()=>{
                             handledateClear()
                            }} 
                           className={`p-2 h-8 w-16 rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-black text-white border border-gray-300`}>
                                Clear
                            </button>
                            <CloseIcon style={{ color: '#B4B4B4', cursor: 'pointer' }} onClick={dateOnCloseHandler} />
                        </div>
                    </div>

                    <Box mt={2}>
                        {/* Integrating the Calendar component */}
                        <Calendar
                            onChange={(value)=>handleOnDateSelector(value)} 
                            value={datestate[0].startDate} // Show selected date
                            className="text-decoration-line: none" // Add custom class if needed
                       
                        />
                    </Box>

                    {/* <div className='flex justify-center mt-4'>
                        <RangeSlider value={value} setValue={setValue} />
                    </div> */}
                </div>
            )}

          
          
             
        </div>
    );
};

export default Filtersdt;
